import axios from "axios";

const BASE_URL = "https://api.enixpack.co.kr/login";

export async function Login(params) {
  const Query = new URLSearchParams({
    id: params.id,
    password: params.password,
  }).toString();

  const response = await axios.post(`${BASE_URL}?${Query}`);
  return response.data;
}
